<template>
  <div>
    <div class="row col-12 page-head">
      <nav aria-label="breadcrumb" role="navigation">
        <ol class="breadcrumb page-head-nav">
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'dashboard' }">Home</router-link>
          </li>
          <li class="breadcrumb-item">
            <router-link-active :to="{ name: 'my-profile' }">Profile</router-link-active>
          </li>
        </ol>
      </nav>
    </div>
    <div class="container-fluid mt--7">
      <div class="row">
        <div class="col-md-12">
          <div class="card shadow-sm">
            <div class="card-header">
              My Account
            </div>

            <div class="card-body">
              <ul class="nav nav-tabs nav-justified mb-5">
                <li class="nav-item">
              <span
                  :class="[
                'nav-link',
                { active: activeTab === 'profile' },
              ]"
                  @click="setActiveTab('profile')"
              >
                My Profile
              </span>
                </li>
                <li class="nav-item">
              <span
                  :class="[
                'nav-link',
                { active: activeTab === 'password-reset' },
              ]"
                  @click="setActiveTab('password-reset')"
              >
                Update Password
              </span>
                </li>
              </ul>

              <div v-if="activeTab === 'profile'">
                <form @submit.prevent="updateProfile">
                  <h4>Basic Information</h4>
                  <hr/>
                  <div class="row">
                    <div class="form-group col-md-6">
                      <label for="first-name">First Name:</label>
                      <input v-if="editProfileMode"
                             type="text"
                             class="form-control"
                             id="first-name"
                             v-model="user.first_name"
                             placeholder="Enter First Name"
                             required
                      />
                      <p v-else class="m-0 p-3 bg-light">{{ user.first_name }}</p>
                    </div>
                    <div class="form-group col-md-6">
                      <label for="last-name">Last Name:</label>
                      <input
                          v-if="editProfileMode"
                          type="text"
                          id="last-name"
                          class="form-control"
                          v-model="user.last_name"
                          placeholder="Enter Last Name"
                          required
                      />
                      <p v-else class="m-0 p-3 bg-light">{{ user.last_name }}</p>
                    </div>
                    <div class="form-group col-md-6">
                      <label for="email">Email:</label>
                      <input
                          v-if="editProfileMode"
                          type="email"
                          id="email"
                          class="form-control"
                          v-model="user.email"
                          placeholder="Enter Email"
                          required
                      />
                      <p class="m-0 p-3 bg-light" v-else>{{ user.email }}</p>
                    </div>
                    <div class="form-group col-md-6">
                      <label for="gender">Gender:</label>
                      <select
                          v-if="editProfileMode"
                          type="text"
                          id="gender"
                          class="form-control"
                          v-model="user.gender"
                          required
                      >
                        <option value="">Select Gender</option>
                        <option value="Female">Female</option>
                        <option value="Male">Male</option>
                        <option value="other">Other</option>
                      </select>
                      <p class="m-0 p-3 bg-light" v-else>{{ user.gender }}</p>
                    </div>
                  </div>
                  <h4>Contact information</h4>
                  <hr/>

                  <div class="row">
                    <div class="form-group col-md-6">
                      <label for="country">Country:</label>
                      <select
                          v-if="editProfileMode"
                          v-model="user.country"
                          class="form-control"
                          id="country"
                          name="country"
                          required
                      >
                        <option value="">{{ user.country }}</option>
                        <option value="Algeria">Algeria</option>
                        <option value="Angola">Angola</option>
                        <option value="Benin">Benin</option>
                        <option value="Botswana">Botswana</option>
                        <option value="Burkina Faso">Burkina Faso</option>
                        <option value="Burundi">Burundi</option>
                        <option value="Cabo Verde">Cabo Verde</option>
                        <option value="Cameroon">Cameroon</option>
                        <option value="Central African Republic">
                          Central African Republic (CAR)
                        </option>
                        <option value="Chad">Chad</option>
                        <option value="Comoros">Comoros</option>
                        <option value="Congo">Congo</option>
                        <option value="Congo">Congo</option>
                        <option value="Cote d'Ivoire">Cote d'Ivoire</option>
                        <option value="Djibouti">Djibouti</option>
                        <option value="Egypt">Egypt</option>
                        <option value="Equatorial Guinea">
                          Equatorial Guinea
                        </option>
                        <option value="Eritrea">Eritrea</option>
                        <option value="Eswatini">Eswatini (formerly Swaziland)</option>
                        <option value="Ethiopia">Ethiopia</option>
                        <option value="Gabon">Gabon</option>
                        <option value="Gambia">Gambia</option>
                        <option value="Ghana">Ghana</option>
                        <option value="Guinea">Guinea</option>
                        <option value="Guinea-Bissau">Guinea-Bissau</option>
                        <option value="Kenya">Kenya</option>
                        <option value="Lesotho">Lesotho</option>
                        <option value="Liberia">Liberia</option>
                        <option value="Libya">Libya</option>
                        <option value="Madagascar">Madagascar</option>
                        <option value="Malawi">Malawi</option>
                        <option value="Mali">Mali</option>
                        <option value="Mauritania">Mauritania</option>
                        <option value="Mauritius">Mauritius</option>
                        <option value="Morocco">Morocco</option>
                        <option value="Mozambique">Mozambique</option>
                        <option value="Namibia">Namibia</option>
                        <option value="Niger">Niger</option>
                        <option value="Nigeria">Nigeria</option>
                        <option value="Rwanda">Rwanda</option>
                        <option value="Sao Tome and Principe">Sao Tome and Principe</option>
                        <option value="Senegal">Senegal</option>
                        <option value="Seychelles">Seychelles</option>
                        <option value="Sierra Leone">Sierra Leone</option>
                        <option value="Somalia">Somalia</option>
                        <option value="South Africa">South Africa</option>
                        <option value="South Sudan">South Sudan</option>
                        <option value="Sudan">Sudan</option>
                        <option value="Tanzania">Tanzania</option>
                        <option value="Togo">Togo</option>
                        <option value="Tunisia">Tunisia</option>
                        <option value="Uganda">Uganda</option>
                        <option value="Zambia">Zambia</option>
                        <option value="Zimbabwe">Zimbabwe</option>
                      </select>
                      <p class="m-0 p-3 bg-light" v-else>{{ user.country }}</p>
                    </div>
                    <div class="form-group col-md-6">
                      <label for="phone">Phone:</label>
                      <vue-tel-input
                          v-if="editProfileMode"
                          id="phone"
                          v-model="user.phone"
                          v-bind="bindProps"
                          placeholder="Phone Number"
                          class="profile-phone"
                          required
                      />
                      <p class="m-0 p-3 bg-light" v-else>{{ user.phone }}</p>
                    </div>
                  </div>
                  <div class="text-center py-3">
                    <button
                        class="btn btn-outline-secondary w-25 mr-3"
                        type="button"
                        v-if="editProfileMode"
                        @click="toggleEditProfileMode">
                      Cancel
                    </button>
                    <button class="btn btn-primary w-25 ml-3" type="submit" v-if="editProfileMode">
                      <span
                          v-if="submitting"
                          class="spinner-border spinner-border-sm"
                      />
                      {{ submitProfile }}
                    </button>
                  </div>
                </form>
                <div class="text-center">
                  <button
                      class="btn btn-primary w-50"
                      type="button"
                      v-if="!editProfileMode"
                      @click="toggleEditProfileMode"
                  >
                      <span
                          v-if="submitting"
                          class="spinner-border spinner-border-sm"
                      />
                    {{ submitProfile }}
                  </button>
                </div>
              </div>

              <form
                  v-if="activeTab === 'password-reset'"
                  data-vv-scope="password"
              >
                <h4>Change Password</h4>
                <hr/>
                <div v-if="error" class="alert alert-danger">
                  {{ error }}
                </div>
                <div class="form-row">
                  <div class="form-group col-md-12">
                    <label class="form-control-label" for="old-password">Old Password:</label>
                    <div class="input-group">
                      <input
                          data-vv-as="old password"
                          v-model.trim="user.old_password"
                          v-validate="'required|min:6|max:50'"
                          :type="passFieldType"
                          name="old-password"
                          id="old-password"
                          placeholder="Old Password"
                          class="form-control"
                          required
                      />
                      <div class="input-group-append showPassDiv">
                        <i
                            :class="[
                                'mdi form-control show_password_icon',
                                showOldPass ? 'mdi-eye-off' : 'mdi-eye',
                              ]"
                            @click="showOldPassword"
                        ></i>
                      </div>
                    </div>
                    <small class="text-danger">
                      {{ errors.first("password.old_password") }}
                    </small>
                  </div>
                  <div class="form-group col-md-12">
                    <label class="form-control-label" for="new-password">New Password:</label>
                    <div class="input-group">
                      <input
                          v-model.trim="user.password"
                          ref="password"
                          v-validate="'required|min:6|max:50'"
                          id="new-password"
                          :type="newPassType"
                          name="password"
                          placeholder="Password"
                          class="form-control"
                          required
                      />
                      <div class="input-group-append showPassDiv">
                        <i
                            :class="[
                                'mdi form-control show_password_icon',
                                showNewPass ? 'mdi-eye-off' : 'mdi-eye',
                              ]"
                            @click="showNewPassword"
                        ></i>
                      </div>
                    </div>
                    <small class="text-danger">
                      {{ errors.first("password.password") }}
                    </small>
                  </div>
                  <div class="form-group col-md-12">
                    <label
                        class="form-control-label"
                        for="password_confirmation"
                    >
                      Confirm New Password:
                    </label>
                    <div class="input-group">
                      <input
                          data-vv-as="password"
                          v-model.trim="user.password_confirmation"
                          v-validate="'required|confirmed:password'"
                          id="password_confirmation"
                          :type="confirmPassType"
                          name="password_confirmation"
                          placeholder="Retype Password"
                          class="form-control"
                          required
                      />
                      <div class="input-group-append showPassDiv">
                        <i
                            :class="[
                                'mdi form-control show_password_icon',
                                showConfirmPass ? 'mdi-eye-off' : 'mdi-eye',
                              ]"
                            @click="showConfirmPassword"
                        ></i>
                      </div>
                    </div>
                    <small class="text-danger">
                      {{ errors.first("password.password_confirmation") }}
                    </small>
                  </div>
                </div>
                <div class="text-center my-3">
                  <button
                      :disabled="submitting"
                      @click="updatePassword"
                      class="btn btn-primary w-50"
                      type="button"
                  >
                    {{ submit }}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";

export default {
  name: "user-profile",
  data() {
    return {
      isActive: false,
      submitting: false,
      user: {},
      error: "",
      submit: "Update Password",
      submitProfile: "Update Profile",
      bindProps: {
        mode: "international",
      },
      showOldPass: false,
      showNewPass: false,
      showConfirmPass: false,
      passFieldType: "password",
      newPassType: "password",
      confirmPassType: "password",
      activeTab: "profile",
      editProfileMode: false,
    };
  },
  mounted() {
    this.getProfile();
  },
  methods: {
    getProfile() {
      this.isActive = true;
      axios({
        method: "GET",
        url: "v1/auth/users/me/",
      }).then(
          (result) => {
            if (result.status === 200) {
              this.user = result.data;
              this.isActive = false;
            }
          },
          (error) => {
            // eslint-disable-next-line no-console
            console.log(error.response);
            this.isActive = false;
          }
      );
    },
    async updateProfile() {
      this.submitting = true;
      this.submitProfile = "Updating Profile";
      const data = {
        first_name: this.user.first_name,
        last_name: this.user.last_name,
        email: this.user.email,
        phone: this.user.phone.split(" ").join(""),
        country: this.user.country,
        gender: this.user.gender,
      };
      await axios
          .patch(`v1/auth/users/me/`, data)
          .then((response) => {
            if (response.status === 200) {
              this.$swal(" ", "Profile Details Successfully updated.", "success");
              this.submitting = false;
              this.submitProfile = "Update Profile";
              // this.$forceUpdate();
              this.toggleEditProfileMode()
            }
          })
          .catch((error) => {
            if (error.response) {
              this.$swal("", "Could not update profile", "error");
            }
          });
    },
    updatePassword() {
      this.error = "";
      this.submitting = true;
      this.$validator
          .validateAll("password")
          .then((result) => {
            if (!result) {
              this.submitting = false;
              return;
            }
            this.submit = "Updating....";
            const data = {
              current_password: this.user.old_password,
              new_password: this.user.password,
            };
            axios({
              method: "POST",
              url: "v1/auth/users/set_password/",
              data: data,
            }).then(
                (result) => {
                  if (result.status === 204) {
                    this.user = {
                      password: "",
                      password_confirmation: "",
                      old_password: "",
                    };
                    this.$validator.reset();
                    this.submit = "Update Password";
                    this.submitting = false;
                    // eslint-disable-next-line no-console
                    this.$swal(
                        "Success",
                        "Password Updated Successfully.",
                        "success"
                    );
                  }
                },
                (error) => {
                  this.submit = "Update Password";
                  this.submitting = false;
                  this.error = error.response.data.errors[0].error;
                  // eslint-disable-next-line no-console
                }
            );
          })
          .catch(() => {
          });
    },
    showOldPassword() {
      this.showOldPass = !this.showOldPass;
    },
    showNewPassword() {
      this.showNewPass = !this.showNewPass;
    },
    showConfirmPassword() {
      this.showConfirmPass = !this.showConfirmPass;
    },
    setActiveTab(tab) {
      this.activeTab = tab;
    },
    toggleEditProfileMode() {
      this.editProfileMode = !this.editProfileMode;
    },
  },
  watch: {
    showOldPass() {
      if (this.showOldPass === true) {
        this.passFieldType = "text";
      } else {
        this.passFieldType = "password";
      }
    },
    showNewPass() {
      if (this.showNewPass === true) {
        this.newPassType = "text";
      } else {
        this.newPassType = "password";
      }
    },
    showConfirmPass() {
      if (this.showConfirmPass === true) {
        this.confirmPassType = "text";
      } else {
        this.confirmPassType = "password";
      }
    },
  },
};
</script>
<style></style>
